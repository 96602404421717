const checkStringContainsValueWithDelimiter = (string, value, delimiter) => {
  if (string) {
    const decompose = string.split(delimiter);
    return decompose.includes(value);
  }
  return null;
};

const splitCTAElement = (string) => {
  const tmp = string.split(/<h4>/);
  const result = tmp.map((part, index) =>
    part.replace("<h4>", "").replace("</h4>", "")
  );
  return result;
};

const getEndOfStringWithDelimiter = (string, delimiter) => {
  const decompose = string.split(delimiter);

  return decompose[decompose.length - 1];
};

const getStartOfStringWithDelimiter = (string, delimiter) => {
  const decompose = string.split(delimiter);
  return decompose[0];
};

const capitalizeFirstLetter = (value) => {
  return value[0].toUpperCase() + value.slice(1);
};

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const removeHtmlTags = (string) => {
  const item = string.replace(/<(.|\n)*?>/g, "");
  return item;
};

const getActionElement = (string) => {
  let actions = [];

  const tmp = string.split("<blockquote>");
  tmp.map((part, index) => {
    part.split(/<\/blockquote>/).map((item, index) => {
      actions.push(item);
    });
  });
  const result = actions.map((part, index) =>
    part.replace("<blockquote>", "").replace("<\\blockquote>", "")
  );

  return result;
};

module.exports = {
  removeHtmlTags,
  splitCTAElement,
  capitalizeFirstLetter,
  escapeRegExp,
  getStartOfStringWithDelimiter,
  getEndOfStringWithDelimiter,
  checkStringContainsValueWithDelimiter,
  getActionElement,
};
