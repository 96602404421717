import React from "react";
import { navigate } from "gatsby";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import SEO from "../../components/seo";
import { removeHtmlTags } from "../../lib/stringHelper";

import { Title, Button, Box, Badge, List } from "../../components/Blog/Core";
import styled from "styled-components";
import Layout from "../../components/Blog/Layout";

const BoxStyled = styled(Box)`
  .block-title {
    color: ${({ theme }) => theme.colors.dark};
    font-size: 21px;
    font-weight: 700;
    line-height: 34px;
  }

  p {
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 15px;
  }
`;

const Location = styled.span`
  color: #8c97ac;
  font-size: 16px;
  font-weight: 300;
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

const BadgeStyled = styled(Badge)`
  background-color: ${({ theme, bg }) => rgba(theme.colors[bg], 0.15)};
  color: ${({ theme, bg }) => theme.colors[bg]};
  border: none;
  font-weight: 300;
`;

const JobOfferDetails = ({ pageContext }) => {
  const { post } = pageContext;
  return (
    <React.Fragment>
      <SEO title={`MARK AI - ${post.title}`} />
      <Layout>
        <BoxStyled>
          <div className="pt-5 mt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="11" className="mb-4 mb-lg-5">
                <Box pt={["40px", null, null, "75px"]}>
                  <Box
                    maxWidth="275px"
                    mx="auto"
                    className="d-flex justify-content-between align-items-center mb-4"
                  >
                    <Location>
                      <i className="icon icon-pin-3 mr-1"></i> Paris, FR
                    </Location>
                    <BadgeStyled bg="secondary">
                      {removeHtmlTags(post.excerpt)}
                    </BadgeStyled>
                  </Box>
                  <Box>
                    <Title variant="hero">{post.title}</Title>
                  </Box>
                </Box>
              </Col>
              <Col lg="8">
                <Box className="mb-5">
                  <div className="mb-5">
                    <h2 className="block-title mb-4">A propos de Mark</h2>
                    <p>
                      Chez Mark, nous aidons les business E-commerce à
                      rentabiliser leurs investissements publicitaires sur les
                      réseaux sociaux sans être expérimenté. Plus simple, moins
                      coûteuse, notre solution est une plateforme tout-en-un
                      proposant un large choix de fonctionnalités et
                      d’automatisation. En d'autres mots, nous rendons la
                      publicité accessible à toute l'industrie E-commerce.
                    </p>
                    <p>
                      Depuis son lancement en Novembre 2020, plus d‘une centaine
                      de boutiques utilisent la plateforme. Notre mission est de
                      créer une plateforme 100% autonome pouvant gérer 1
                      milliard de dollars / mois de dépenses publicitaires en
                      unissant toutes les connaissances, compétences et
                      technologies nécessaires pour réussir avec Google et
                      Facebook Ads.
                    </p>
                    <p>
                      Nous pensons que Mark AI représente non seulement le futur
                      du marketing mais aussi le futur du travail en entreprise:
                      en collaboration avec la machine. L’entreprise a été créée
                      par 2 diplômés d’Efrei Paris, Selim Chehimi & Kilian
                      Poulin. Nous avons lancé ce projet durant notre dernière
                      année d’études et avons notamment remporté le prix
                      national "Pépite".
                    </p>
                  </div>
                  <div className="mb-5">
                    <h2 className="block-title mb-4">Mission</h2>
                    <p>
                      Pour accélérer le développement de la plateforme, nous
                      recherchons un développeur fullstack JS.
                    </p>
                    <p>
                      Travaillant en collaboration avec le CTO, tes missions
                      seront réparties autour de 3 enjeux principaux:
                    </p>
                    <List>
                      <li>
                        Participer pleinement au développement de nouvelles
                        fonctionnalités et à l’automatisation de certaines
                        fonctionnalités déjà présentes sur la plateforme.
                      </li>
                      <li>Amélioration continue du code</li>
                      <li>
                        Application de la méthodologie DevOps avec mise en place
                        de tests unitaires.
                      </li>
                    </List>
                    <p>
                      Tu participeras à la stratégie générale de l’entreprise
                      c’est à dire que tes missions seront également:
                    </p>
                    <List>
                      <li>
                        Assister l’équipe dans les choix de développement et
                        d’architecture.
                      </li>
                      <li>
                        Prioriser le développement de certaines fonctionnalités
                        en fonction des enjeux business et des requêtes clients.
                      </li>
                      <li>Participer aux réflexions, partager tes idées.</li>
                    </List>
                    <p>
                      Si tu as une forte envie d’apprendre et d’utiliser les
                      nouvelles technologies, tu auras la possibilité d’explorer
                      le modèle de Deep Learning le plus avancé (GPT-3 de
                      OpenAI) sur lequel se basent certaines de nos
                      fonctionnalités.
                    </p>
                    <p>
                      Les besoins peuvent changer rapidement en startup, tu
                      seras donc amené à travailler sur de nombreux projets avec
                      des enjeux différents (fonctionnalité, automatisation,
                      amélioration du code, DevOps, correction de bugs).
                    </p>
                  </div>
                  <div className="mb-5">
                    <h2 className="block-title mb-4">Profil recherché </h2>
                    <p>
                      Nous recherchons quelqu'un d'autonome avec un minimum de
                      connaissances sur certaines technologies:
                    </p>
                    <List>
                      <li>
                        Maîtrise de Javascript et expérience avec React{" "}
                        <ol>Bonus: expérience avec Next.js, Redux</ol>
                      </li>
                      <li>Redux Expérience avec l’architecture MVC</li>
                      <li> Bonne maîtrise de l’anglais</li>
                      <li> Expérience avec l’utilisation/la création d’API</li>
                      <li> Rigueur dans l’écriture du code</li>
                      <li>
                        Autonomie, capacité à comprendre et résoudre les
                        problèmes
                      </li>
                      <li>Qualité de travail en équipe</li>
                    </List>

                    <p>
                      Nous sommes encore une petite équipe, d’où l’importance de
                      recruter quelqu’un de curieux, autonome et qui souhaite
                      prendre des responsabilités pendant ce stage. Il y a
                      d’innombrables opportunités à saisir pour quiconque
                      souhaite avoir un impact.
                    </p>
                  </div>

                  <div className="mb-5">
                    <h2 className="block-title mb-4">
                      Notre environnement technique
                    </h2>
                    <p>Frontend: React et Next.js (JS)</p>
                    <p>Backend: API Express.js (JS)</p>
                    <p>DevOps: Tests unitaires + Git et CI/CD avec Github</p>
                    <p>
                      Infrastructure: Containers Docker sur des droplets
                      DigitalOcean (migration sur AWS prévue pour début 2021)
                      avec bases de données MySQL et MongoDB.{" "}
                    </p>
                  </div>

                  <div className="mb-5">
                    <h2 className="block-title mb-4">Lieu de travail</h2>
                    <p>
                      Incubateur de l'Efrei / possibilité de travailler certains
                      jours en remote
                    </p>
                  </div>
                  <div className="contant-block-4">
                    <div className="pt-5">
                      <Button
                        mb={5}
                        onClick={() => navigate(`/career/${post.slug}/apply`)}
                      >
                        Envoyer ma candidature
                      </Button>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </BoxStyled>
      </Layout>
    </React.Fragment>
  );
};
export default JobOfferDetails;
